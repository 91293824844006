<template>
  <div
    class="px-0 profile content-wrapper mt-md-3 mt-0"
    v-if="currentUser && currentUser.id"
  >
    <div class="d-md-flex">
      <div
        class="
          col-md-2
          p-2
          mr-3
          mobile-transparent
          justify-content-between
          d-flex
          flex-column
        "
      >
        <div class="profile-detail mx-auto text-center">
          <div class="avatar-container text-center mx-auto">
            <figure class="avatar">
              <avatar
                width="150px"
                height="150px"
                :name="currentUser.full_name"
                :image="currentUser.photo"
              />
              <span>{{ currentUser.is_online ? "Online" : "Offline" }}</span>
            </figure>
          </div>
          <div class="mt-4 text-center">
            <h5 class="card-title mb-0">{{ currentUser.full_name }}</h5>
            <p class="text-muted mb-2">{{ currentUser.profile.name }}</p>
          </div>
        </div>
      </div>
      <b-card
        :class="[
          'w-100  mobile-transparent p-md-4 p-2 no-border',
          is_mobile ? 'min-h-50' : 'min-h-75',
        ]"
        no-body
      >
        <div
          class="
            d-flex
            flex-md-row flex-column-reverse
            align-items-md-center
            justify-content-between
          "
        >
          <strong class="mb-3 d-block">Dados pessoais</strong>
          <div
            class="
              actions
              align-items-end
              d-flex
              gap-2
              justify-content-between
              mb-3 mb-md-0
            "
          >
            <b-button
              class="align-items-center d-flex gap-1 p-3"
              size="sm"
              variant="light"
              @click="handleEditUser(currentUser.id)"
              active
            >
              <b-icon-pencil />
            </b-button>
            <b-button
              v-if="currentUser.id !== user.id"
              class="align-items-center d-flex gap-1 p-3"
              size="sm"
              @click="handleConfirmDelete(currentUser)"
              variant="light"
            >
              <b-icon-trash />
            </b-button>
            <b-button
              :disabled="loadingPass"
              class="align-items-center d-flex gap-1"
              size="sm"
              @click="handleSendResetPassword()"
              variant="success"
            >
              <b-spinner v-if="loadingPass" small class="mr-2" />
              <span>Recuperar senha</span>
            </b-button>
            <!-- <b-button
              class="align-items-center d-flex gap-1"
              size="sm"
              @click="handleEditUserData(!currentUser.is_active)"
              :variant="`${currentUser.is_active ? 'warning' : 'success'}`"
            >
              <span>{{ currentUser.is_active ? "Desativar" : "Ativar" }}</span>
            </b-button> -->

            <b-button
              v-if="currentUser.id !== user.id && user.is_admin"
              class="align-items-center d-flex gap-1"
              size="sm"
              @click="handleStartViewProvider"
              variant="primary"
            >
              <b-icon-play />
              <span>Simular</span>
            </b-button>
          </div>
        </div>

        <div>
          <section class="mb-4">
            <span>Nome completo</span>
            <h6 class="mt-2 mr-3 d-flex align-items-center">
              <b-icon-person-badge />
              <span class="px-2">{{ currentUser.full_name }}</span>
            </h6>
          </section>

          <b-form-group label="Perfil:" class="col-4 p-0">
            <multiselect
              required
              searchable
              close-on-select
              :show-labels="false"
              :internal-search="false"
              placeholder="Selecione um Estado"
              label="name"
              v-model.trim="currentUser.profile"
              :options="profileOptions"
              @select="handleChangeProfile"
            />
          </b-form-group>
          <section class="mb-4">
            <span>E-mail</span>
            <h6 class="mt-2 mr-3 d-flex align-items-center">
              <b-icon-envelope />
              <span class="px-2">{{ currentUser.email }}</span>
            </h6>
          </section>
        </div>
        <hr />
        <strong class="mb-3 d-block">Empresa</strong>
        <div
          class="mb-3 min-h-26"
          body-class="d-flex justify-content-between flex-column align-item-end"
        >
          <div v-if="enterprise">
            <h5 class="d-block mb-3">
              {{ enterprise.social_reason }}
            </h5>
            <cards-list grid="3">
              <div>
                <div class="mb-2">
                  CNPJ:
                  <strong>{{
                    enterprise.cnpj | VMask("##.###.###/####-##")
                  }}</strong>
                </div>
                <div class="mb-2">
                  Situação Cadastral:
                  <strong>{{ enterprise.active ? "Ativa" : "Baixada" }}</strong>
                </div>
                <div>
                  Nome Fantasia: <strong>{{ enterprise.fantasy_name }}</strong>
                </div>
              </div>
              <div>
                <div class="mb-2">
                  Data de abertura:
                  <strong>{{ enterprise.founded_at | date }}</strong>
                </div>
                <div>
                  Natureza Jurídica: <strong>{{ enterprise.segment }}</strong>
                </div>
              </div>
              <div>
                <div class="mb-2">
                  E-mail: <strong>{{ enterprise.email }}</strong>
                </div>
                <div>CEP: <strong>31620-460</strong></div>
              </div>
            </cards-list>
          </div>
        </div>
      </b-card>
    </div>
    <b-modal :id="`delete-row_${currentUser.id}`" centered size="md">
      <template #modal-header> <h5>Cuidado!</h5></template>
      <p>
        Você apagará o responsével legal
        <strong class="text-danger">{{ currentUser.full_name }}</strong
        >. Essa ação não poderá ser desfeita. <br />
        deseja continuar?
      </p>
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="handleDeleteRow()"> Sim </b-button>
        <b-button variant="primary" @click="close()">Não</b-button>
      </template>
    </b-modal>
    <b-modal
      id="add-user"
      centered
      hide-footer
      size="md"
      no-close-on-backdrop
      :title="`Editar usuário`"
    >
      <Form
        :id="selectedId"
        :profile="selectedProfile"
        :current_enterprise="currentEnterprise"
        :isAdd="false"
        :permissions="summary"
        @submited="handleCloseModal"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { listPaginated } from "@/mixins/listPaginated";
import Form from "./form";

export default {
  mixins: [listPaginated],

  components: {
    Form,
  },
  computed: {
    ...mapGetters(["is_mobile", "user"]),
  },
  data() {
    return {
      id: "",
      action_name: "user",
      selectedId: "",
      currentUser: {},
      currentEnterprise: {},
      enterprise: {},
      selectedProfile: {},
      loading: false,
      loadingPass: false,
      profileOptions: [],
    };
  },

  methods: {
    ...mapActions([
      "get_user_by_id",
      "toggle_provider",
      "set_is_preview",
      "edit_user",
      "recover",
      "show_toast",
      "get_all_profile",
    ]),

    getUser() {
      this.get_user_by_id({ id: this.id }).then((result) => {
        this.currentUser = result;
        this.enterprise = result.enterprise;
        this.selectedProfile = result.profile;

        if (result.enterprise) {
          this.currentEnterprise = result.enterprise;
        }
      });
    },

    handleStartViewProvider() {
      localStorage.setItem(
        "personify",
        JSON.stringify({ ...this.enterprise, user_id: this.currentUser.id })
      );
      this.toggle_provider(true);
      this.set_is_preview(true);
      if (!this.$route.path.split("/").includes("dashboard")) {
        this.$router.push("/main/dashboard");
      }
    },

    handleEditUser(id) {
      this.isAdd = false;
      this.selectedId = id;
      this.$bvModal.show("add-user");
    },

    handleCloseModal() {
      this.$bvModal.hide("add-user");
      this.getUser();
    },

    handleEditUserData(is_active) {
      this.edit_user({
        id: this.id,
        is_active: is_active,
      }).then(() => this.getUser());
    },

    handleSendResetPassword() {
      this.loadingPass = true;

      const { email } = this.currentUser;

      this.recover({ email })
        .then(() => {
          this.loadingPass = false;
          this.show_toast({
            message: `Um link de recuperação foi enviado para ${email} com sucesso`,
            type: "success",
          });
        })
        .catch(() => {
          this.loadingPass = false;
          this.show_toast({
            message: `Não conseguimos enviar o link de recuperação foi enviado para ${email}`,
            type: "error",
          });
        });
    },

    handleChangeProfile(profile) {
      const { name, id } = this.currentUser;
      this.edit_user({ id, profile_id: profile.id }).then(() => {
        this.show_toast({
          message: `Perfil do usuário ${name} alterado com sucesso`,
          type: "success",
        });
      });
    },
  },

  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getUser();
    }
    this.$root.$on("deleted-event", () => {
      this.$router.push("/users/list");
    });
    this.get_all_profile().then(({ data }) => {
      this.profileOptions = data.results;
    });
  },
};
</script>
<style lang="scss">
.profile {
  max-width: $box;
  border-radius: 6px;

  .avatar-container {
    position: relative;
    > section {
      margin: 0 auto;
    }
    .initials {
      height: 142px;
      width: 142px;
      border-radius: 150px;
      font-size: 50px;
    }
  }
}
</style>
